// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-album-sort-js": () => import("../src/pages/album-sort.js" /* webpackChunkName: "component---src-pages-album-sort-js" */),
  "component---src-pages-album-js": () => import("../src/pages/album.js" /* webpackChunkName: "component---src-pages-album-js" */),
  "component---src-pages-albums-js": () => import("../src/pages/albums.js" /* webpackChunkName: "component---src-pages-albums-js" */),
  "component---src-pages-calendar-js": () => import("../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-day-js": () => import("../src/pages/day.js" /* webpackChunkName: "component---src-pages-day-js" */),
  "component---src-pages-help-js": () => import("../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-people-js": () => import("../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */)
}

